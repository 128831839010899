<template>
  <b-alert
    class="position-fixed fixed-top ml-0 mb-0 mr-0 rounded-0 notifyMe"
    style="z-index: 2000;"
    id="notification-toast"
    :show="showStatus"
    @dismissed="hideToast"
    :dismissible="true"
    :variant="variantColor"
  >
    <div :class="classObject">
      <slot name="message"></slot>
    </div>
  </b-alert>
</template>

<script>
import EventBus from '@/event-bus'

export default {
  name: 'NotificationComponent',
  props: {
    message: {
      type: String,
      default: 'Spirecta has been updated, please refresh your browser'
    },
    variant: {
      type: String,
      default: 'success'
    },
    msgClass: {
      type: String,
      default: 'notifyClass'
    },
    autoHide: {
      type: Boolean,
      default: true
    },
    autoHideDelay: {
      type: Number,
      default: 5000
    },
    reloadPage: {
      type: Boolean,
      default: false
    },
    persist: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showStatus: false,
      variantColor: 'success',
      autohideTimer: 30000,
      autohideStatus: false,
      messageText: null,
      reload: false
    }
  },
  created () {
    EventBus.$on('notification-show', this.handleShow)
  },
  destroyed () {
    EventBus.$off('notification-show')
    const el = document.getElementById('header_wrapper')
    const docMargins = Number(el.style.marginTop.toString().replace('px', ''))
    el.style.marginTop = (docMargins > 0 ? (docMargins - 50) : 0) + 'px'
  },
  computed: {
    slotExists () {
      return Object.keys(this.$slots).length > 0
    },
    classObject () {
      const className = {
        msg: true
      }
      if (this.msgClass && this.msgClass.trim().length > 0) {
        className[this.msgClass] = true
      }
      return className
    }
  },
  methods: {
    hideToast () {
      this.showStatus = false
    },
    displayToast () {
      this.showStatus = true
    },
    createDynamicSlot (message) {
      const messageDiv = this.$createElement('div', message)
      this.$slots.message = [messageDiv]
    },
    handleShow (options = {}) {
      const configuration = options || {}
      if (Object.keys(configuration).length === 0) {
        if (!this.slotExists) {
          this.createDynamicSlot(this.messageText)
        }
        this.displayToast()
      } else {
        if (Object.prototype.hasOwnProperty.call(configuration, 'autoHide') && configuration.autoHide !== null && typeof configuration.autoHide === 'boolean') {
          this.autohideStatus = configuration.autoHide
        }
        if (Object.prototype.hasOwnProperty.call(configuration, 'autoHideDelay') && configuration.autoHideDelay && configuration.autoHideDelay !== null && typeof configuration.autoHideDelay === 'number') {
          this.autohideTimer = configuration.autoHideDelay
        }
        if (Object.prototype.hasOwnProperty.call(configuration, 'variant') && configuration.variant && configuration.variant !== null && typeof configuration.variant === 'string') {
          this.variantColor = configuration.variant
        }
        if (Object.prototype.hasOwnProperty.call(configuration, 'message') && configuration.message && configuration.message !== null && typeof configuration.message === 'string') {
          this.messageText = configuration.message
          this.$nextTick(function () {
            this.createDynamicSlot(this.messageText)
          })
        }
        if (Object.prototype.hasOwnProperty.call(configuration, 'reloadPage') && configuration.reloadPage && configuration.reloadPage !== null && typeof configuration.reloadPage === 'boolean') {
          this.reload = configuration.reloadPage
        }
        this.displayToast()
      }
    },
    startAutoHideTimer () {
      if (this.autohideStatus && this.autohideTimer && typeof this.autohideTimer === 'number' && this.autohideTimer > 0) {
        setTimeout(function () {
          this.hideToast()
        }.bind(this), this.autohideTimer)
      }
    }
  },
  watch: {
    $route: {
      handler: function () {
        if (this.showStatus === true) {
          this.showStatus = this.persist === true
        }
      }
    },
    message: {
      handler: function () {
        if (this.message) {
          this.messageText = this.message
        }
      },
      immediate: true
    },
    autoHide: {
      handler: function () {
        if (this.autoHide === true || this.autoHide === false) {
          this.autohideStatus = this.autoHide
        }
      },
      immediate: true
    },
    autoHideDelay: {
      handler: function () {
        if (this.autoHideDelay) {
          this.autohideTimer = this.autoHideDelay
        }
      },
      immediate: true
    },
    variant: {
      handler: function () {
        if (this.variant) {
          this.variantColor = this.variant
        }
      },
      immediate: true
    },
    reloadPage: {
      handler: function () {
        if (this.reloadPage) {
          this.reload = this.reloadPage
        }
      },
      immediate: true
    },
    showStatus: function (value) {
      if (value === true || value === false) {
        if (value === true) {
          this.startAutoHideTimer()
          EventBus.$emit('show-notification')
          const el = document.getElementById('header_wrapper')
          el.style.marginTop = (Number(el.style.marginTop.toString().replace('px', '')) + 50) + 'px'
          setTimeout(function () {
            const notificationComp = document.getElementsByClassName('notifyMe')
            if (notificationComp) {
              const notificationDivs = Array.prototype.filter.call(notificationComp, function (testElement) {
                return testElement.nodeName === 'DIV'
              })
              notificationDivs.map((item, index) => {
                notificationComp[index].style.marginTop = (index * 50) + 'px'
              })
            }
          }, 100)
        } else {
          const el = document.getElementById('header_wrapper')
          const docMargins = Number(el.style.marginTop.toString().replace('px', ''))
          el.style.marginTop = (docMargins > 0 ? (docMargins - 50) : 0) + 'px'
          EventBus.$emit('hide-notification', {
            reload: this.reload
          })
          setTimeout(function () {
            const notificationComp = document.getElementsByClassName('notifyMe')
            if (notificationComp) {
              const notificationDivs = Array.prototype.filter.call(notificationComp, function (testElement) {
                return testElement.nodeName === 'DIV'
              })
              notificationDivs.map((item, index) => {
                notificationComp[index].style.marginTop = (Number(notificationComp[index].style.marginTop.toString().replace('px', '')) - 50) + 'px'
              })
            }
          }, 100)
        }
      }
    }
  }
}
</script>

<style lang="scss">
#notification-toast {
  text-align: center;
  p {
    margin-bottom: 0 !important;
  }
  a{
    color: #0065FF;
    font-weight: 600;
  }
}
</style>
