<template>
  <b-container id="site-footer" class="vfooter-main mx-auto print-none"  style="background: #9b9cae">
    <b-row>
      <b-col class="text-left left-container" cols="12" lg="2" xl="2">
        <div class="text-white logo-text">
          <b-img :src="require('@/assets/img/spirecta-logo.png')" fluid/><br />
          <div class="text-white copyright-text">{{ $t('common.footer.copyright') }}</div>
        </div>
      </b-col>
      <b-col align-self="center" cols="12" lg="8" xl="8">
        <ul class="d-flex flex-column flex-sm-row justify-content-center text-white m-auto">
          <li class="my-2 my-sm-0 mr-sm-5" v-if="!isAuthenticated"><b-link to="/login">{{$t('common.footer.login')}}</b-link></li>
          <li class="my-2 my-sm-0 mr-sm-5" v-if="!isAuthenticated"><b-link :href="$t('common.footer.homepage_url')">{{$t('common.footer.homepage')}}</b-link></li>
          <li class="my-2 my-sm-0 mr-sm-5"><b-link href="https://forum.spirecta.se/t/kom-igang-ratt-och-latt-med-spirecta-borja-har/14" target="_blank">{{$t('common.footer.get_started_guide')}}</b-link></li>
          <li class="my-2 my-sm-0 mr-sm-5" v-if="isAuthenticated"><b-link href="https://forum.spirecta.se/t/manual-koncept-och-artiklar-innehallsforteckning/59" target="_blank">{{$t('common.footer.manual')}}</b-link></li>
          <li class="my-2 my-sm-0 mr-sm-5"><b-link href="#" @click="onHelpClick">{{$t('common.footer.help')}}</b-link></li>
          <li class="my-2 my-sm-0 mr-sm-5"><b-link href="https://forum.spirecta.se/c/buggar-och-fel/6"  target="_blank">{{$t('common.footer.feedback')}}</b-link></li>
        </ul>
      </b-col>
      <b-col class="text-center" cols="12" lg="2" xl="2" align-self="center">
        <b-link @click.stop="switchLanguage('en')"><img :alt="$t('common.footer.change_language')" class="flag-link" src="/img/en.png" /></b-link>
        <b-link @click.stop="switchLanguage('sv')"><img :alt="$t('common.footer.change_language')" class="flag-link" src="/img/sv.png" /></b-link>
        <b-link @click.stop="switchLanguage('da')"><img :alt="$t('common.footer.change_language')" class="flag-link" src="/img/da.png" /></b-link><br/>
        <span v-if="isAuthenticated"><b-link to="/logout">{{$t('common.footer.logout')}}</b-link></span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { SET_SETTINGS_ACTIVE_LANG_ID } from '@/store/actions/settings'

export default {
  name: 'vfooter',
  computed: {
    ...mapState('user', ['currentUser', 'isAuthenticated'])
  },
  methods: {
    switchLanguage (langCode) {
      this.$store.dispatch(SET_SETTINGS_ACTIVE_LANG_ID, langCode)
    },
    onHelpClick (e) {
      e.preventDefault()
      this.$zendesk.toggle()
    }
  }
}
</script>

<style lang="scss" scoped>
  .vfooter-main {
    padding: 50px 36px;
    border-radius: 30px;
    @media (max-width: 575.98px) {
      padding: 32px 36px;
    }

    .left-container {
      .logo-text {
        text-align:center;
        img {
          max-width: 160px;
          margin-bottom: 5px;
        }
        .copyright-text {
          font-size: 12px;
          line-height: 16px;
          text-align:center;
        }
      }
    }

    ul {
      list-style: none;
      @media screen and (max-width:991.98px) {
        padding: 0;
      }
      li {
        font-weight: lighter;
      }
    }

    a{
      color:white;
      font-size: 14px;
    }

    .flag-link {
      border-radius: 3px 6px 4px 3px;
      transform: skewX(-10deg);
      margin-right:5px;
    }
  }
</style>
