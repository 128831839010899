import axios from 'axios'
import router from '@/router'
import store from './store/index'
import { SpirectaApp } from './main'
import axiosRetry from 'axios-retry'
axiosRetry(axios, {
  retries: 1,
  retryDelay: (retryCount) => {
    return retryCount * 3000
  }
})

export const setupAxiosDefaults = function (auth) {
  if (auth && auth.access_token) {
    axios.defaults.headers.common.Authorization = auth.token_type + ' ' + auth.access_token
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    axios.defaults.headers.post.Accept = 'application/json'
    axios.defaults.headers.common.href = window.location.href

    axios.interceptors.response.use(
      response => { return response },
      error => {
        if (!error.status && error.response.status === 500) { // Handle network error
          console.error('Spirecta experienced network error when trying to fetch data from API. Please contact support.')
          SpirectaApp.$bvToast.toast(SpirectaApp.$t('common.network_error_description'), {
            title: SpirectaApp.$t('common.network_error_title'),
            variant: 'danger',
            solid: true
          })
        }

        if (error.response.status === 401 || error.response.status === 400) {
          console.error('Got unauthorized response, will try to handle it by refreshing, then logging out')

          const originalRequest = error.config
          originalRequest._retry = true
          let refreshToken = false
          let auth = window.localStorage.getItem('auth', null)

          if (auth) { auth = JSON.parse(auth) }
          if (auth) {
            refreshToken = auth.refresh_token
          }

          if (refreshToken) {
            return axios.post(`${process.env.VUE_APP_ROOT_API_EXCL_API_PREFIX + '/oauth/token/refresh'}`, { refreshToken })
              .then(({ response }) => {
                window.localStorage.setItem('auth', response)
                axios.defaults.headers.common.Authorization = 'Bearer ' + response.access_token
                originalRequest.headers.Authorization = 'Bearer ' + response.access_token

                return axios(originalRequest)
              })
              // eslint-disable-next-line handle-callback-err
              .catch(error => {
                console.error('Tried to refresh but got an error there as well. Logging out!')
                store.dispatch('user/logout')
                router.push({ name: 'login' }).catch(() => {
                  console.error('already log out')
                })
              })
          }

          console.error('Could not refresh, killing session and logging out:')
          store.dispatch('user/logout')
          router.push({ name: 'login' }).catch(() => {
            console.log('cannot redirect')
            router.go('/login')
          })
        }
        return Promise.reject(error)
      })
  }
}

export const getAuth = function () {
  let auth = localStorage.getItem('auth', null)

  if (auth !== null) {
    auth = JSON.parse(auth)
  }

  return auth
}
