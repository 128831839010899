<template>
  <div></div>
</template>

<script>
import EventBus from '@/event-bus'

export default {
  name: 'BootstrapToast',
  data () {
    return {
    }
  },
  created () {
    EventBus.$on('makeToast', res => {
      const optionSolid = typeof res.solid === 'undefined' ? true : res.solid
      const optionAutoHideDelay = typeof res.autoHideDelay === 'undefined' ? 5000 : res.autoHideDelay
      let optionVariant = typeof res.variant === 'undefined' ? '' : res.variant
      const optionToaster = typeof res.toaster === 'undefined' ? 'b-toaster-top-right' : res.toaster
      let optionTitle = typeof res.title === 'undefined' ? null : res.title
      const optionText = typeof res.text === 'undefined' ? null : res.text

      if (typeof (res.status) !== 'undefined' && optionVariant === '') { optionVariant = res.status } // Backwards compatibility so we can use status in emit
      if (optionVariant === 'error') { optionVariant = 'danger' } // Let's be able to use error in callback

      switch (optionVariant) {
        case 'success' :
          if (optionTitle === null) { optionTitle = this.$t('common.notifications.success_title') }
          break
        case 'danger' :
          if (optionTitle === null) { optionTitle = this.$t('common.notifications.error_title') }
          break
      }

      this.$bvToast.toast(optionText, {
        title: optionTitle,
        variant: optionVariant,
        solid: optionSolid,
        autoHideDelay: optionAutoHideDelay,
        toaster: optionToaster
      })
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
