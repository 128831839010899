import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    /* {
      path: '/',
      name: 'login',
      component: () => import('./views/login/Login.vue'),
      alias: '/login'
    }, */
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/registration-is-not-completed',
      name: 'registration-is-not-completed',
      component: () => import('./views/RegistrationIsNotCompleted.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/login/v2/Login.vue')
    },
    {
      path: '/login-bankid',
      name: 'loginWithBank',
      component: () => import('./views/login/v2/LoginWithBank.vue')
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import('./views/login/v2/ForgotPassword.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('./views/login/Logout.vue')
    },
    {
      path: '/404',
      name: 'notFound',
      component: () => import('./views/404.vue')
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: () => import('./views/login/v2/ResetPassword.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: { requiresAuth: true },
      component: () => import('./views/Dashboard.vue')
    },
    {
      path: '/dashboard2',
      name: 'dashboard2',
      meta: { requiresAuth: true },
      component: () => import('./views/Dashboard2.vue')
    },
    /* Simulator routes */
    {
      path: '/simulators',
      meta: { requiresAuth: true },
      component: () => import('./views/simulators/index.vue'),
      children: [
        { path: 'pension', redirect: 'pension/amount' },
        { path: 'pension/:tab', name: 'simulator-pension', component: () => import('./views/simulators/Pension/PensionSimulator.vue') },
        { path: 'mortage', redirect: 'mortage/simple' },
        { path: 'mortage/:tab', name: 'simulator-mortage', component: () => import('./views/simulators/Mortage/MortageSimulator') },
        { path: 'electricity', redirect: 'electricity/simple' },
        { path: 'electricity/:tab', name: 'simulator-electricity', component: () => import('./views/simulators/Electricity/ElectricitySimulator') },
        { path: 'compounded-interest', name: 'simulators-compounded-interest', component: () => import('./views/simulators/CompoundedInterest/CompoundedInterestSimulator.vue') }
      ]
    },
    /* Incomes and expenses-routes */
    { path: '/incomes-expenses', redirect: '/transactions/view' },
    { path: '/incomes-expenses/accounts/income', redirect: '/reports/other/account-plan-v2/incomes' },
    { path: '/incomes-expenses/accounts/expense', redirect: '/reports/other/account-plan-v2/expenses' },
    {
      path: '/incomes-expenses',
      meta: { requiresAuth: true },
      component: () => import('./views/incomes-expenses/index.vue'),
      children: [
        {
          path: 'accounts/income/:account_id/edit',
          name: 'incomes-accounts-edit',
          component: () =>
            import(
              './views/incomes-expenses/accounts/wizard-steps/wizardAccountIncome.vue'
            )
        },
        {
          path: 'accounts/expense/:account_id/edit',
          name: 'expenses-accounts-edit',
          component: () =>
            import(
              './views/incomes-expenses/accounts/wizard-steps/wizardAccountExpense.vue'
            )
        },
        {
          path: 'accounts/create/income',
          name: 'incomes-accounts-wizard-create',
          component: () =>
            import(
              './views/incomes-expenses/accounts/wizard-steps/wizardAccountIncome.vue'
            )
        },
        {
          path: 'accounts/create/expense',
          name: 'expenses-accounts-wizard-create',
          component: () =>
            import(
              './views/incomes-expenses/accounts/wizard-steps/wizardAccountExpense.vue'
            )
        },
        {
          path: 'accounts/groups/:account_group_id/edit',
          name: 'incomes-expenses-groups-edit',
          component: () =>
            import(
              './views/incomes-expenses/accounts/groups/wizardAccountGroup.vue'
            )
        },
        {
          path: 'accounts/groups/create/:account_group_type',
          name: 'incomes-expenses-groups-wizard-create',
          component: () =>
            import(
              './views/incomes-expenses/accounts/groups/wizardAccountGroup.vue'
            )
        },
        { path: 'budget', name: 'incomes-expenses-budget', redirect: '/reports/other/account-plan-v2/budget' }
      ]
    },
    /* Assets and liabilities-routes */
    { path: '/assets-liabilities', redirect: '/assets-liabilities/transactions/view' },
    { path: '/assets-liabilities/asset', redirect: '/reports/other/account-plan-v2/assets' },
    { path: '/assets-liabilities/liability', redirect: '/reports/other/account-plan-v2/liabilities' },
    {
      path: '/assets-liabilities',
      meta: { requiresAuth: true },
      component: () => import('./views/assets-liabilities/index.vue'),
      children: [
        {
          path: 'import-from-tink',
          name: 'import-accounts-from-tink',
          component: () =>
            import('./views/assets-liabilities/create-from-tink-wizard/CreateFromTinkWizard.vue')
        },
        {
          path: 'update-values-from-tink',
          name: 'update-values-from-tink',
          component: () =>
            import('./views/assets-liabilities/update-values-from-tink-wizard/UpdateValuesFromTinkWizard.vue')
        },
        {
          path: 'accounts/asset/:account_id/edit',
          name: 'asset-accounts-edit',
          component: () =>
            import('./views/assets-liabilities/accounts/wizardAccountAsset.vue')
        },
        {
          path: 'accounts/liability/:account_id/edit',
          name: 'liability-accounts-edit',
          component: () =>
            import(
              './views/assets-liabilities/accounts/wizardAccountLiability.vue'
            )
        },
        {
          path: 'accounts/create/asset',
          name: 'assets-accounts-wizard-create',
          component: () =>
            import('./views/assets-liabilities/accounts/wizardAccountAsset.vue')
        },
        {
          path: 'accounts/create/liability',
          name: 'liabilities-accounts-wizard-create',
          component: () =>
            import(
              './views/assets-liabilities/accounts/wizardAccountLiability.vue'
            )
        },
        {
          path: 'accounts/groups/:account_group_id/edit',
          name: 'assets-liabilities-groups-edit',
          component: () =>
            import(
              './views/incomes-expenses/accounts/groups/wizardAccountGroup.vue'
            )
        },
        {
          path: 'accounts/groups/create/:account_group_type',
          name: 'assets-liabilities-groups-wizard-create',
          component: () =>
            import(
              './views/incomes-expenses/accounts/groups/wizardAccountGroup.vue'
            )
        },
        {
          path: 'update-value/:account_type/:id?',
          name: 'assets-liabilities-update-value',
          component: () =>
            import('./views/assets-liabilities/updatevalue/index.vue')
        }
      ]
    },
    /* Budgets */
    { path: '/budget', redirect: '/incomes-expenses/budget' }, // If url is entered directly, redirect
    {
      path: '/budget',
      meta: { requiresAuth: true },
      component: () => import('./views/budget/index.vue'),
      children: [
        {
          path: 'account/:account_id?/:year?',
          name: 'budget-single',
          component: () =>
            import('./views/budget/account/BudgetSingleAccount.vue')
        },
        { path: 'accounts', redirect: 'accounts/incomes' },
        {
          path: 'accounts/:tab?/:subtab?',
          name: 'budget-multiple',
          component: () =>
            import('./views/budget/accounts/BudgetMultipleAccounts.vue')
        }
      ]
    },

    /* Reports and insights-routes */
    { path: '/reports', redirect: '/reports/performance' }, // If url is entered directly, redirect
    {
      path: '/reports',
      meta: { requiresAuth: true },
      component: () => import('./views/reports/index.vue'),
      children: [
        {
          path: 'performance',
          name: 'reports-performance',
          props: { reportType: 'performance' },
          component: () => import('./views/reports/AllReports.vue')
        },

        { path: 'performance/result', redirect: 'performance/result/overview' },
        {
          path: 'performance/result/:tab',
          name: 'reports-performance-result',
          component: () =>
            import('./views/reports/performance/Performance/Performance.vue')
        },
        {
          path: 'performance/active-passive',
          redirect: 'performance/active-passive/summary'
        },
        {
          path: 'performance/active-passive/:tab',
          name: 'active-passive-report',
          component: () => import('./views/reports/performance/ActivePassiveV2/ActivePassiveReportV2')
        },
        {
          path: 'performance/12-month-result',
          redirect: 'performance/over-time/result'
        },
        {
          path: 'performance/over-time',
          redirect: 'performance/over-time/result'
        },
        {
          path: 'performance/over-time/:tab',
          name: 'reports-performance-over-time',
          component: () => import('./views/reports/performance/PerformanceOverTimeReport/PerformanceOverTimeReport.vue')
        },

        {
          path: 'performance/savings-ratio',
          redirect: 'performance/savings-ratio/percentage'
        },
        {
          path: 'performance/savings-ratio/:tab',
          name: 'reports-savings-ratio',
          component: () =>
            import('./views/reports/performance/SavingsRatio/SavingsRatio.vue')
        },

        {
          path: 'performance/financial-freedom',
          redirect: 'performance/financial-freedom/percentage'
        },
        {
          path: 'performance/financial-freedom/:tab',
          name: 'reports-financial-freedom',
          component: () =>
            import(
              './views/reports/performance/FinancialFreedom/FinancialFreedom.vue'
            )
        },
        {
          path: 'performance/financial-security',
          redirect: 'performance/financial-security/percentage'
        },
        {
          path: 'performance/financial-security/:tab',
          name: 'reports-financial-security',
          component: () =>
            import(
              './views/reports/performance/FinancialSecurity/FinancialSecurity.vue'
            )
        },
        {
          path: 'performance/cashflow-quadrant',
          redirect: 'performance/cashflow-quadrant/summary'
        },
        {
          path: 'performance/cashflow-quadrant/:tab',
          name: 'reports-cashflow-quadrant',
          component: () =>
            import(
              './views/reports/performance/CashflowQuadrant/CashlowQuadrantPerformanceReport.vue'
            )
        },
        {
          path: 'performance/accounts/:account_id/view',
          redirect:
            'performance/accounts/:account_id/view/summary'
        },
        {
          path: 'performance/accounts/:account_id/view/:tab/:subtab?',
          name: 'account-performance',
          component: () =>
            import('./views/reports/AccountReport')
        },
        {
          path: 'performance/account-groups/:account_group_id',
          redirect:
            'performance/account-groups/:account_group_id/twelve-months-rolling'
        },
        {
          path: 'performance/account-groups/:account_group_id/:tab',
          name: 'account-group-performance',
          component: () =>
            import(
              './views/reports/performance/AccountGroup2/AccountGroupReport2.vue'
            )
        },
        {
          path: 'performance/split-expenses',
          name: 'split-expenses-performance',
          component: () =>
            import(
              './views/reports/performance/SplitExpenses/SplitExpenses.vue'
            )
        },

        {
          path: 'balance',
          name: 'reports-balance',
          props: { reportType: 'balance' },
          component: () => import('./views/reports/AllReports.vue')
        },

        { path: 'balance/report', redirect: 'balance/report/networth' },
        { path: 'balance/report/:tab', name: 'balance-report', component: () => import('./views/reports/balance/BalanceReport/BalanceReport.vue') },
        { path: 'balance/productive-assets', redirect: 'balance/productive-assets/summary' },
        { path: 'balance/productive-assets/:tab', name: 'productive-assets-balance-report', component: () => import('./views/reports/balance/ProductiveAssets/ProductiveAssetsBalanceReport') },
        { path: 'balance/risk-scale', redirect: 'balance/risk-scale/summary' },
        { path: 'balance/risk-scale/:tab', name: 'risk-scale-balance-report', component: () => import('./views/reports/balance/RiskScale/RiskScaleBalanceReport') },
        { path: 'balance/free-restricted-capital', redirect: 'balance/free-restricted-capital/summary' },
        { path: 'balance/free-restricted-capital/:tab', name: 'free-restricted-capital-balance-report', component: () => import('./views/reports/balance/FreeRestrictedCapital/FreeRestrictedCapitalBalanceReport') },
        { path: 'balance/four-buckets', redirect: 'balance/four-buckets/summary' },
        { path: 'balance/four-buckets/:tab', name: 'four-buckets', component: () => import('./views/reports/balance/FourBuckets2/FourBuckets.vue') },
        { path: 'balance/subtypes', redirect: 'balance/subtypes/assets' },
        { path: 'balance/subtypes/:tab', name: 'asset-types-report', component: () => import('./views/reports/balance/AssetLiabilitySubtypes/AssetLiabilitySubtypesReport.vue') },
        { path: 'balance/asset-allocation', redirect: 'balance/asset-allocation/total' },
        { path: 'balance/asset-allocation/:tab', name: 'asset-allocation', component: () => import('./views/reports/balance/AssetAllocation/AssetAllocationReport.vue') },
        { path: 'balance/over-time-report', redirect: 'balance/over-time-report/networth' },
        { path: 'balance/over-time-report/:tab', name: 'over-time-report', component: () => import('./views/reports/balance/BalanceReportOverTime/BalanceReportOverTime.vue') },
        { path: 'balance/asset-growth', redirect: 'balance/asset-growth/summary' },
        { path: 'balance/asset-growth/:tab', name: 'asset-growth-report', component: () => import('./views/reports/balance/AssetGrowth/AssetGrowth.vue') },
        { path: 'other', name: 'reports-other', props: { reportType: 'other' }, component: () => import('./views/reports/AllReports.vue') },

        /* Reports :: Other :: Financial overview */
        { path: 'other/financial-overview/:tab', name: 'reports-other-financial-overview', component: () => import('./views/reports/other/financial-overview/FinancialOverviewReport') },
        { path: 'other/financial-overview', redirect: 'other/financial-overview/summary' },
        { path: 'other/financial-overview/summary', name: 'reports-other-financial-overview-summary', props: { reportType: 'other' }, component: () => import('./views/reports/other/financial-overview/FinancialOverviewReport') },

        /* Reports :: Other :: Status check */
        { path: 'other/status-check', redirect: 'transactions/view/predefined_selections' },
        { path: 'other/account-plan-v2', redirect: 'other/account-plan-v2/incomes' },
        { path: 'other/account-plan-v2/incomes', name: 'account-plan-incomes-overview', component: () => import('./views/reports/other/account-plan-v2/AccountPlanIncomesOverview') },
        { path: 'other/account-plan-v2/expenses', name: 'account-plan-expenses-overview', component: () => import('./views/reports/other/account-plan-v2/AccountPlanExpensesOverview') },
        { path: 'other/account-plan-v2/assets', name: 'account-plan-assets-overview', component: () => import('./views/reports/other/account-plan-v2/AccountPlanAssetsOverview') },
        { path: 'other/account-plan-v2/assets/values', name: 'account-plan-assets-values', component: () => import('./views/reports/other/account-plan-v2/AccountPlanValues') },
        { path: 'other/account-plan-v2/liabilities/values', name: 'account-plan-liabilities-values', component: () => import('./views/reports/other/account-plan-v2/AccountPlanValues') },
        { path: 'other/account-plan-v2/assets/update-values', name: 'account-plan-assets-update-values', component: () => import('./views/reports/other/account-plan-v2/AccountPlanAssetsUpdateValues') },
        { path: 'other/account-plan-v2/liabilities/update-values', name: 'account-plan-liabilities-update-values', component: () => import('./views/reports/other/account-plan-v2/AccountPlanLiabilitiesUpdateValues') },
        { path: 'other/account-plan-v2/liabilities', name: 'account-plan-liabilities-overview', component: () => import('./views/reports/other/account-plan-v2/AccountPlanLiabilitiesOverview') },
        { path: 'other/account-plan-v2/by-name', name: 'account-plan-by-name', component: () => import('./views/reports/other/account-plan-v2/AccountPlanByName') },
        { path: 'other/account-plan-v2/incomes/match-to-globals', name: 'account-plan-incomes-match-globals', component: () => import('./views/reports/other/account-plan-v2/AccountPlanIncomesMatchGlobals') },
        { path: 'other/account-plan-v2/incomes/ownership', name: 'account-plan-incomes-ownership', component: () => import('./views/reports/other/account-plan-v2/AccountPlanOwnerShip') },
        { path: 'other/account-plan-v2/incomes/stats', name: 'account-plan-incomes-stats', component: () => import('./views/reports/other/account-plan-v2/AccountPlanStats') },
        { path: 'other/account-plan-v2/expenses/stats', name: 'account-plan-expenses-stats', component: () => import('./views/reports/other/account-plan-v2/AccountPlanStats') },
        { path: 'other/account-plan-v2/assets/stats', name: 'account-plan-assets-stats', component: () => import('./views/reports/other/account-plan-v2/AccountPlanStats') },
        { path: 'other/account-plan-v2/liabilities/stats', name: 'account-plan-liabilities-stats', component: () => import('./views/reports/other/account-plan-v2/AccountPlanStats') },
        { path: 'other/account-plan-v2/expense/ownership', name: 'account-plan-expense-ownership', component: () => import('./views/reports/other/account-plan-v2/AccountPlanOwnerShip') },
        { path: 'other/account-plan-v2/assets/ownership', name: 'account-plan-assets-ownership', component: () => import('./views/reports/other/account-plan-v2/AccountPlanOwnerShip') },
        { path: 'other/account-plan-v2/liabilties/ownership', name: 'account-plan-liabilities-ownership', component: () => import('./views/reports/other/account-plan-v2/AccountPlanOwnerShip') },
        { path: 'other/account-plan-v2/expenses/match-to-globals', name: 'account-plan-expenses-match-globals', component: () => import('./views/reports/other/account-plan-v2/AccountPlanExpensesMatchGlobals') },
        { path: 'other/account-plan-v2/assets/match-to-globals', name: 'account-plan-assets-match-globals', component: () => import('./views/reports/other/account-plan-v2/AccountPlanAssetsMatchGlobals') },
        { path: 'other/account-plan-v2/liabilities/match-to-globals', name: 'account-plan-liabilities-match-globals', component: () => import('./views/reports/other/account-plan-v2/AccountPlanLiabilitiesMatchGlobals') },
        { path: 'other/account-plan-v2/assets/four-buckets', name: 'account-plan-assets-four-buckets', component: () => import('./views/reports/other/account-plan-v2/AccountPlanAssetsFourBuckets') },
        { path: 'other/account-plan-v2/liabilities/four-buckets', name: 'account-plan-liabilities-four-buckets', component: () => import('./views/reports/other/account-plan-v2/AccountPlanLiabilitiesFourBuckets') },
        { path: 'other/account-plan-v2/assets/allocation', name: 'account-plan-assets-allocation', component: () => import('./views/reports/other/account-plan-v2/AccountPlanAssetsAllocation') },
        { path: 'other/account-plan-v2/assets/types', name: 'account-plan-assets-categorization', component: () => import('./views/reports/other/account-plan-v2/AccountPlanAssetsCategorization') },
        { path: 'other/account-plan-v2/liabilities/types', name: 'account-plan-liabilities-categorization', component: () => import('./views/reports/other/account-plan-v2/AccountPlanLiabilitiesCategorization') },
        { path: 'other/account-plan-v2/incomes/misc', name: 'account-plan-incomes-misc', component: () => import('./views/reports/other/account-plan-v2/AccountPlanIncomesMisc') },
        { path: 'other/account-plan-v2/incomes/auto-import', name: 'account-plan-incomes-tink', component: () => import('./views/reports/other/account-plan-v2/AccountPlanIncomesTink') },
        { path: 'other/account-plan-v2/expenses/misc', name: 'account-plan-expenses-misc', component: () => import('./views/reports/other/account-plan-v2/AccountPlanExpensesMisc') },
        { path: 'other/account-plan-v2/assets/misc', name: 'account-plan-assets-misc', component: () => import('./views/reports/other/account-plan-v2/AccountPlanAssetsMisc') },
        { path: 'other/account-plan-v2/liabilities/misc', name: 'account-plan-liabilities-misc', component: () => import('./views/reports/other/account-plan-v2/AccountPlanLiabilitiesMisc') },
        { path: 'other/account-plan-v2/expenses/auto-import', name: 'account-plan-expenses-tink', component: () => import('./views/reports/other/account-plan-v2/AccountPlanExpensesTink') },
        { path: 'other/account-plan-v2/assets/auto-import', name: 'account-plan-assets-tink', component: () => import('./views/reports/other/account-plan-v2/AccountPlanAssetsTink') },
        { path: 'other/account-plan-v2/liabilities/auto-import', name: 'account-plan-liabilities-tink', component: () => import('./views/reports/other/account-plan-v2/AccountPlanLiabilitiesTink') },
        { path: 'other/account-plan-v2/budget', name: 'account-plan-budget-overview', component: () => import('./views/reports/other/account-plan-v2/AccountPlanBudget') },
        {
          path: 'other/cash-forecast/:tab?',
          name: 'cash-forecast-report',
          component: () => import('./views/reports/other/cash-forecast')
        },
        {
          path: 'budget',
          name: 'reports-budget',
          props: { reportType: 'budget' },
          component: () => import('./views/reports/AllReports.vue')
        },
        { path: 'budget/period-report', redirect: 'budget/period-report/period' },
        {
          path: 'budget/period-report/:tab',
          name: 'period-budget-report',
          component: () =>
            import('./views/reports/budget/period-budget/PeriodBudgetReport')
        },
        /** Performance budget **/
        { path: 'budget/12-month', redirect: 'budget/12-month/basic' },
        { path: 'budget/12-month/:tab/:year?', redirect: 'budget/performance/summary' },
        { path: 'budget/performance', redirect: 'budget/performance/summary' },
        {
          path: 'budget/performance/:tab',
          name: 'performance-budget-report',
          component: () => import('./views/reports/budget/performance-budget-report/PerformanceBudgetReport')
        },
        {
          path: 'budget/lifeplan',
          redirect: 'budget/lifeplan/summary'
        },
        {
          path: 'budget/lifeplan/:tab',
          name: 'lifeplan',
          component: () => import('./views/reports/budget/lifeplan-budget-report/LifeplanBudgetReport.vue')
        },
        {
          path: 'simulators',
          name: 'reports-simulators',
          props: { reportType: 'simulator' },
          component: () => import('./views/reports/AllReports.vue')
        }
      ]
    },

    /* Transactions */
    { path: '/transactions', redirect: '/transactions/view' },
    {
      path: '/transactions',
      meta: { requiresAuth: true },
      component: () => import('./views/transactions/index.vue'),
      children: [
        { path: 'balance-fix/:account_id?', name: 'balance-fix', component: () => import('./views/transactions/balance-fix/BalanceFix.vue') },
        { path: 'create/single', name: 'transactions-create', component: () => import('./views/transactions/create/createTransaction.vue') },
        { path: 'create/multiple', name: 'transactions-create-multiple', component: () => import('./views/transactions/create-multiple/createMultipleTransactions.vue') },
        { path: 'edit/multiple-wizard', name: 'transactions-edit-multiple-wizard', component: () => import('./views/transactions/re-categorise/ReCategoriseMultiStep.vue') },
        { path: 'import', redirect: 'import/all' },
        { path: 'import/all', name: 'incomes-expenses-import', component: () => import('./views/transactions/_inc/TransactionImportCards.vue') },
        { path: 'import/account-statement', name: 'transactions-import-account-statement', component: () => import('./views/transactions/import-account-statement/ImportAccountStatementV2.vue') },
        { path: 'import/excel', name: 'transactions-import-excel', component: () => import('./views/transactions/import-excel/ImportAccountStatementSpreadsheet.vue') },
        { path: 'import/excel-advanced', name: 'transaction-import-excel-advanced', component: () => import('./views/transactions/import-excel-advanced/ImportAccountStatementSpreadsheetAdvanced.vue') },
        { path: 'import/tink', name: 'transactions-import-tink', component: () => import('./views/transactions/import-tink/ImportTink.vue') },
        { path: 'import/excel/sandbox', name: 'transactions-import-excel-sandbox', component: () => import('./views/transactions/import-excel/ImportAccountStatementSpreadsheet.vue') },
        { path: 'import/rules', name: 'ie-rules', component: () => import('./views/transactions/import-rules/ImportRules.vue') },
        { path: 'import/rules/create', name: 'ie-rules-create', component: () => import('./views/transactions/import-rules/ImportRulesWizard.vue') },
        { path: 'import/rules/:rule_id/edit', name: 'ie-rules-edit', component: () => import('./views/transactions/import-rules/ImportRulesWizard.vue') },
        { path: 'tags', name: 'transaction-tags', component: () => import('./views/transactions/tags/TransactionTags.vue') },
        { path: 'tags/create', name: 'transaction-tags-create', component: () => import('./views/transactions/tags/WizardTag.vue') },
        { path: 'tags/edit/:tag_id', name: 'transaction-tags-edit', component: () => import('./views/transactions/tags/WizardTag.vue') },
        { path: 'ledger', redirect: 'view/transactions' },
        { path: 'view/transaction/:transaction_id(\\d+)', name: 'transactions-create-single', component: () => import('./views/transactions/create/createTransaction.vue') },
        { path: 'view', redirect: 'view/transactions' },
        { path: 'view/:tab/:subtab?', name: 'transactions-view', component: () => import('./views/transactions/view-v2/TransactionsView.vue') }
      ]
    },

    /* User settings */
    { path: '/settings', redirect: '/settings/your-details' },
    {
      path: '/settings',
      meta: { requiresAuth: true },
      component: () => import('./views/settings/index.vue'),
      children: [
        { path: 'subscription', redirect: 'subscription/plans' },
        {
          path: 'subscription/:tab',
          name: 'settings-subscription-v2',
          component: () => import('./views/settings/subscription-v2/Subscription.vue')
        },
        {
          path: 'your-details',
          name: 'settings-your-details',
          component: () => import('./views/settings/your-details/YourDetails.vue')
        },
        {
          path: 'change-password',
          name: 'setting-change-password',
          component: () => import('./views/settings/your-details/YourDetailsChangePassword.vue')
        },
        { path: 'chart-of-account', redirect: 'chart-of-account/finances' },
        {
          path: 'chart-of-account/:tab',
          name: 'setting-chartofaccount',
          component: () => import('./views/settings/coa/ChartAccount.vue')
        },
        {
          path: 'household',
          name: 'setting-household',
          component: () => import('./views/settings/coa/Household.vue')
        },
        {
          path: 'your-data',
          redirect: 'your-data/statistics'
        },
        {
          path: 'your-data/:tabname',
          name: 'settings-your-data',
          component: () => import('./views/settings/your-data/YourData.vue')
        },
        {
          path: 'balansbladet-import',
          name: 'setting-balansbladet-import',
          component: () => import('./views/settings/balansbladet-import')
        }
      ]
    },
    {
      path: '/redirect',
      name: 'redirect',
      meta: { requiresAuth: true },
      component: () => import('./components/common/Redirecter.vue')
    },
    /* Sandbox routes */
    {
      path: '/sandbox',
      component: () => import('./views/sandbox/index.vue'),
      children: [
        { path: 'period-selector', component: () => import('./views/sandbox/views/PeriodSelectorExample.vue') },
        { path: 'column-toggler', component: () => import('./views/sandbox/views/ColumnTogglerExample.vue') },
        { path: 'report-filter', component: () => import('./views/sandbox/views/ReportFilterExample.vue') },
        { path: 'create-tink-connection', component: () => import('./views/sandbox/views/CreateTinkConnectionExample.vue') },
        { path: 'pdf', component: () => import('./views/sandbox/views/PdfSandboxExample.vue') },
        { path: 'custom-tab-view', component: () => import('./views/sandbox/views/CustomTabFilterView') },
        { path: 'import-view', component: () => import('./views/sandbox/views/ImportExperiment') },
        { path: 'simple-view', component: () => import('./views/sandbox/views/SimpleView') },
        { path: 'combination-chart-bar-and-line', component: () => import('./views/sandbox/views/CombinationChartBarAndLine') },
        { path: 'chart-js', component: () => import('./views/sandbox/views/ChartJsExperiment') },
        { path: 'environment-info', component: () => import('./views/sandbox/views/EnvironmentInfo') },
        { path: 'report-template', component: () => import('./views/sandbox/views/ReportTemplate/ReportTemplate') },
        { path: 'vueselect', component: () => import('./views/sandbox/views/VueselectExperiment') },
        { path: 'mobile', component: () => import('./views/sandbox/views/MobileDropdown') },
        { path: 'difference-chart', component: () => import('./views/sandbox/views/SpirectaDifferenceChart') },
        { path: 'bar-chart', component: () => import('./views/sandbox/views/BarChartExample') },
        { path: 'cashflow-chart', component: () => import('./views/sandbox/views/CashflowExample') },
        { path: 'cashbook-chart', component: () => import('./views/sandbox/views/CashbookExample') },
        { path: 'sunburst-2levels', component: () => import('./views/sandbox/views/SunburstChart2Levels') },
        { path: 'sunburst-treemap', component: () => import('./views/sandbox/views/SunburstChartAndTreemap') },
        { path: 'tink-link', component: () => import('./views/sandbox/views/TinkLink') },
        { path: 'nested-treemap', component: () => import('./views/sandbox/views/NestedTreemap') },
        { path: 'income-expense-12-months', component: () => import('./views/sandbox/views/TwelveMothsExample') },
        { path: 'notification', component: () => import('./views/sandbox/views/NotificationExperiment') },
        { path: 'performance', props: { reportType: 'performance' }, component: () => import('./views/sandbox/views/LatestDesignTemplate/AllReport') },
        { path: 'balance', props: { reportType: 'balance' }, component: () => import('./views/sandbox/views/LatestDesignTemplate/AllReport') },
        { path: 'budget', props: { reportType: 'budget' }, component: () => import('./views/sandbox/views/LatestDesignTemplate/AllReport') },
        { path: 'other', props: { reportType: 'other' }, component: () => import('./views/sandbox/views/LatestDesignTemplate/AllReport') },
        { path: 'simulators', props: { reportType: 'simulator' }, component: () => import('./views/sandbox/views/LatestDesignTemplate/AllReport') },
        { path: 'accounts/create/assets', component: () => import('./views/sandbox/views/LatestDesignTemplate/accountsV2/wizardAccountAsset') },
        { path: 'accounts/create/liabilities', component: () => import('./views/sandbox/views/LatestDesignTemplate/accountsV2/wizardAccountLiability') },
        { path: 'balance/subtypes', name: 'sandbox-asset-types-report', component: () => import('./views/sandbox/views/LatestDesignTemplate/AssetLiabilitySubtypes/AssetLiabilitySubtypesReport.vue') },
        { path: 'create-account-group-account-modal', name: 'sandbox-create-account-group-account-modal', component: () => import('./views/sandbox/views/CreateAccountGroupAccountShowModal') },
        { path: 'autocomplete-input', name: 'autocomplete-input', component: () => import('./views/sandbox/views/AutocompleteInputExample') },
        { path: 'currency-input', name: 'currency-input', component: () => import('./views/sandbox/views/CurrencyInputExample') },
        { path: 'custom-number-input', name: 'custom-number-input', component: () => import('./views/sandbox/views/CustomNumberInputExample') }
      ]
    },
    // and finally the default route, when none of the above matches:
    { path: '*', name: '404', component: () => import('./views/404.vue') }
  ]
})
