import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'font-awesome/css/font-awesome.css'
import i18n from './lang/lang'
import Vuelidate from 'vuelidate'
import VueD3 from 'vue-d3'
import BootstrapVue from 'bootstrap-vue'
import Zendesk from '@dansmaculotte/vue-zendesk'
// import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'

// import filters from './assets/filters'
/* loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
  .then((stripe) => {
    Vue.prototype.StripeModule = stripe
  })
  .catch((error) => console.log(error))
*/
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(VueD3)
Vue.use(require('vue-moment'))
Vue.use(Zendesk, {
  key: '6b7969b6-dea9-4096-a77f-83ce17744bbc',
  disabled: false,
  hideOnLoad: false,
  settings: {
    webWidget: {
      color: {
        theme: '#86cbac'
      }
    }
  }
})

// Vue.use(filters)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const pageMetaTitle = to.meta && to.meta.title ? to.meta.title : null
  window.document.title = pageMetaTitle ? process.env.VUE_APP_TITLE + ' : ' + pageMetaTitle : process.env.VUE_APP_TITLE
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.user.isAuthenticated) {
      next({ name: 'login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})
router.afterEach((to, from) => {
  axios.defaults.headers.common.href = window.location.href // Always send correct current href to backend in requests.
})

export const SpirectaApp = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
