<template>
  <div>
    <div class="container-fluid vheader-main" id="header_wrapper">
      <header id="site-header">
        <nav role="navigation" class="d-flex align-items-center mx-auto">
          <template v-if="isAuthenticated">
            <b-link to="/dashboard" class="livsplan-navbar-brand-link">
              <div class="livsplan-navbar-brand">
                <b-img :src="require('@/assets/img/spirecta-logo.png')" fluid/>
              </div>
            </b-link>
          </template>
          <template v-else>
            <b-link :href="$t('common.footer.homepage_url')" class="livsplan-navbar-brand-link">
              <div class="livsplan-navbar-brand">
                <b-img :src="require('@/assets/img/spirecta-logo.png')" fluid/>
              </div>
            </b-link>
          </template>
          <a href="javascript:void(0);" class="collapse-menu" v-bind:class="{ 'is-expand': isNavExpanded }"
             v-if="isAuthenticated" @click="toggle_nav()">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </a>
          <div class="active-link mobileOpenLink" v-if="isAuthenticated" @click="toggle_nav()">
            {{$t(translationPath + 'menu')}}
          </div>
          <ul class="main-nav my-auto print-none" v-bind:class="{ 'is-expand': isNavExpanded }" v-if="isAuthenticated">

            <!-- Reports and insights -->
            <b-nav-item-dropdown :text="$t(translationPath + 'reports_and_insights')" left
                                 v-bind:class="{ active: isMenuReportsActive }" class="left-link" :disabled="true" v-if="!subscriptionExp">
              <b-dropdown-item to="/reports/performance">{{ $t('reports._common.performance_reports') }}</b-dropdown-item>
              <b-dropdown-item to="/reports/balance">{{ $t('reports._common.balance_reports') }}</b-dropdown-item>
              <b-dropdown-item to="/reports/budget">{{ $t('reports._common.budget_reports') }}</b-dropdown-item>
              <b-dropdown-item to="/reports/other">{{ $t('reports._common.other_reports') }}</b-dropdown-item>
              <b-dropdown-item to="/reports/simulators">{{ $t('reports._common.simulator_reports') }}</b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- END Reports dropdown -->

            <!-- Transactions dropdown -->
            <b-nav-item-dropdown :text="$t(translationPath + 'transactions.transactions')" left
                                 v-bind:class="{ active: isMenuTransactionsActive }" class="left-link" :disabled="true" v-if="!subscriptionExp">
              <b-dropdown-item to="/transactions/view">{{$t(translationPath + 'transactions.view') }}</b-dropdown-item>
              <b-dropdown-item to="/transactions/import/account-statement">{{$t(translationPath + 'transactions.import_from_bank') }}</b-dropdown-item>
              <b-dropdown-item to="/transactions/import/tink">{{$t(translationPath + 'transactions.import_from_bank_auto_tink') }}</b-dropdown-item>
              <b-dropdown-item to="/transactions/create/single">{{ $t(translationPath + 'transactions.add_single') }}</b-dropdown-item>
              <b-dropdown-item to="/transactions/balance-fix">{{ $t(translationPath + 'transactions.balance_fix') }}</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/transactions/import/all">{{$t(translationPath + 'transactions.all_options') }}</b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- END Transactions dropdown -->

            <!-- Manage -->
            <b-nav-item-dropdown :text="$t(translationPath + 'manage_title')" left
                                 v-bind:class="{ active: isMenuAdminActive }" class="left-link" :disabled="true" v-if="!subscriptionExp">

              <b-dropdown-item to="/reports/other/account-plan-v2/incomes">{{ $t(translationPath + 'manage.income_categories') }}</b-dropdown-item>
              <b-dropdown-item to="/reports/other/account-plan-v2/expenses">{{ $t(translationPath + 'manage.expense_categories') }}</b-dropdown-item>
              <b-dropdown-item to="/reports/other/account-plan-v2/assets">{{ $t(translationPath + 'manage.assets') }}</b-dropdown-item>
              <b-dropdown-item to="/reports/other/account-plan-v2/liabilities">{{ $t(translationPath + 'manage.liabilities') }}</b-dropdown-item>
              <b-dropdown-item to="/incomes-expenses/budget">{{ $t(translationPath + 'manage.budget') }}</b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- END Manage -->

            <!--
            ********************************************
            COLLAPSED MENUS BELOW
            ********************************************
            -->

            <!-- COLLAPSED MENU :: Reports -->
            <li class="sub-left-link" v-bind:class="{ active: isMenuReportsActive }" v-if="!subscriptionExp">
              <b-link :to="'/reports/performance'" class="collapsed-main-menu-item" @click="toggle_nav()"><span>{{ $t(translationPath + 'reports_and_insights') }}</span></b-link>
              <ul>
                <li><b-link to="/reports/performance" @click="toggle_nav()">{{ $t('reports._common.performance_reports') }}</b-link></li>
                <li><b-link to="/reports/balance" @click="toggle_nav()">{{ $t('reports._common.balance_reports') }}</b-link></li>
                <li><b-link to="/reports/budget" @click="toggle_nav()">{{ $t('reports._common.budget_reports') }}</b-link></li>
                <li><b-link to="/reports/other" @click="toggle_nav()">{{ $t('reports._common.other_reports') }}</b-link></li>
                <li><b-link to="/reports/simulators" @click="toggle_nav()">{{ $t('reports._common.simulator_reports') }}</b-link></li>
              </ul>
            </li>

            <!-- COLLAPSED MENU :: Transactions -->
            <li class="sub-left-link" v-bind:class="{ active: isMenuTransactionsActive }" v-if="!subscriptionExp">
              <b-link :to="'/transactions/view'" class="collapsed-main-menu-item" @click="toggle_nav()"><span>{{ $t(translationPath + 'transactions.transactions') }}</span></b-link>
              <ul>
                <li><b-link to="/transactions/view" @click="toggle_nav()">{{ $t(translationPath + 'transactions.view') }}</b-link></li>
                <li><b-link to="/transactions/import" @click="toggle_nav()">{{ $t(translationPath + 'transactions.import') }}</b-link></li>
                <li><b-link to="/transactions/import/tink" @click="toggle_nav()">{{ $t(translationPath + 'transactions.import_from_bank_auto_tink') }}</b-link></li>
                <li><b-link to="/transactions/balance-fix" @click="toggle_nav()">{{ $t(translationPath + 'transactions.balance_fix') }}</b-link></li>
                <li><b-link to="/transactions/import/rules" @click="toggle_nav()">{{ $t(translationPath + 'transactions.import_rules') }}</b-link></li>
              </ul>
            </li>

            <!-- COLLAPSED MENU :: Income and expenses -->
            <li class="sub-left-link" v-bind:class="{ active: isMenuAdminActive }" v-if="!subscriptionExp">
              <b-link :to="'/incomes-expenses/accounts/income'" class="collapsed-main-menu-item" @click="toggle_nav()"><span>{{ $t(translationPath + 'manage_title') }}</span></b-link>
              <ul>
                <li><b-link to="/reports/other/account-plan-v2/incomes" @click="toggle_nav()">{{ $t(translationPath + 'manage.income_categories') }}</b-link></li>
                <li><b-link to="/reports/other/account-plan-v2/expenses" @click="toggle_nav()">{{ $t(translationPath + 'manage.expense_categories') }}</b-link></li>
                <li><b-link to="/reports/other/account-plan-v2/assets" @click="toggle_nav()">{{ $t(translationPath + 'manage.assets') }}</b-link></li>
                <li><b-link to="/reports/other/account-plan-v2/liabilities" @click="toggle_nav()">{{ $t(translationPath + 'manage.liabilities') }}</b-link></li>
                <li><b-link to="/incomes-expenses/budget" @click="toggle_nav()">{{ $t(translationPath + 'incomes_expenses.budget') }}</b-link></li>
              </ul>
            </li>

            <!-- USER MENU -->
            <li class="nav-item b-nav-dropdown dropdown left-link last-menu-link">
              <b-nav-item-dropdown right no-caret :disabled="true">
                <template slot="button-content">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-user pr-2" style="font-size: 20px;padding-right: 0;"></i> <span
                      class="text-capitalize">{{ currentUser.firstname }}</span>
                    <i
                        class="fa fa-ellipsis-v ml-2"></i>
                  </div>
                </template>
                <b-dropdown-item to="/settings/your-details">{{ $t(translationPath + 'user_menu.settings') }}</b-dropdown-item>
                <!--<b-dropdown-item href="https://forum.spirecta.se/t/valkommen-till-kom-igang-guiden-for-spirecta/14" target="_blank">{{ $t(translationPath + 'user_menu.getting_started_guide') }}</b-dropdown-item>-->
                <b-dropdown-item href="https://forum.spirecta.se/" target="_blank">{{ $t(translationPath + 'user_menu.forum') }}</b-dropdown-item>
                <b-dropdown-item href="https://forum.spirecta.se/tag/ny-version" target="_blank">{{ $t(translationPath + 'user_menu.updates') }}</b-dropdown-item>
                <template v-if="coas.length > 1">
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item v-for="coa in coas" :key="coa.id" href="/settings/chart-of-account/manage" @click.prevent="onCoaActivateClick(coa)">
                    <template v-if="coa.id === currentCOA.id">
                      <span class="current-coa-link initial-color">{{ coa.title }}</span>
                    </template>
                    <template v-else>
                      {{ $t(translationPath + 'user_menu.activate_coa', { title: coa.title }) }}
                    </template>
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                </template>
                <b-dropdown-item @click="logout">{{ $t(translationPath + 'user_menu.logout') }}
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </li>
            <li :class="{'sub-right-link profile-settings-link': true, 'mt-4': subscriptionExp}"><b-link to="/settings/your-details" @click="toggle_nav()"><span>{{ $t(translationPath + 'user_menu.settings') }}</span></b-link>
            </li>
            <li class="sub-right-link">
              <b-link href="https://forum.spirecta.se/" target="_blank"><span>{{ $t(translationPath + 'user_menu.forum') }}</span></b-link>
            </li>
            <li class="sub-right-link">
              <b-link href="https://forum.spirecta.se/c/information-fran-spirecta/uppdateringar-i-verktyget/22" target="_blank"><span>{{ $t(translationPath + 'user_menu.updates') }}</span></b-link>
            </li>
            <template v-if="coas.length > 1">
              <li v-for="coa in coas" :key="coa.id" class="sub-right-link">
                <template v-if="coa.id === currentCOA.id">
                  <b-link href=""><span class="current-coa-link">{{ coa.title }}</span></b-link>
                </template>
                <template v-else>
                  <b-link href="/settings/chart-of-account/manage" @click.prevent="onCoaActivateClick(coa)"><span>{{ $t(translationPath + 'user_menu.activate_coa', { title: coa.title }) }}</span></b-link>
                </template>
              </li>
            </template>
            <li class="sub-right-link">
              <a @click="logout"><span>{{ $t(translationPath + 'user_menu.logout') }}</span></a>
            </li>
          </ul>
        </nav>
      </header>
    </div>
    <div id="print-header" style="display: none">
      <img :src="require('@/assets/img/logo-green.png')" alt="Spirecta"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'

export default {
  name: 'vheader',
  data () {
    return {
      isMenuAdminActive: this.$router.history.current.path.indexOf('/reports/other/account-plan-v2/') === 0,
      isMenuReportsActive: this.$router.history.current.path.indexOf('/reports/') === 0 && this.$router.history.current.path.indexOf('/reports/other/account-plan-v2/') !== 0,
      isMenuTransactionsActive: this.$router.history.current.path.indexOf('/transactions/') === 0,
      isNavExpanded: false,
      translationPath: 'common.header.header_menu.',
      coas: []
    }
  },
  computed: {
    ...mapState('user', ['isAuthenticated', 'currentUser', 'currentCOA', 'subscriptionExp']),
    activeLanguage () {
      return this.$store.getters.getActiveLang
    }
  },
  watch: {
    $route () {
      this.isMenuAdminActive = this.$router.history.current.path.indexOf('/reports/other/account-plan-v2/') === 0
      this.isMenuReportsActive = this.$router.history.current.path.indexOf('/reports/') === 0 && this.$router.history.current.path.indexOf('/reports/other/account-plan-v2/') !== 0
      this.isMenuTransactionsActive = this.$router.history.current.path.indexOf('/transactions/') === 0
    },
    'currectCOA.id' () {
      this.sortCoas()
    }
  },
  methods: {
    ...mapActions('user', ['logout', 'loadCurrentCOA']),
    toggle_nav () {
      this.isNavExpanded = !this.isNavExpanded
    },
    onCoaActivateClick (item) {
      if (item.id === this.currentCOA.id) {
        return false
      }

      axios.put(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/activate/${item.id}`)
        .then(response => {
          this.$bvToast.toast(
            this.$t('settings.tab_chart_of_accounts.coa_activated', { title: item.title }),
            {
              title: this.$t('settings.tab_charts_of_accounts.title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
          this.loadCurrentCOA()
            .then(() => {
              this.$router.go()
            })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(
            this.$t('settings.tab_chart_of_accounts.coa_not_activated'),
            {
              title: this.$t('settings.tab_charts_of_accounts.title'),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            }
          )
        })
    },
    sortCoas () {
      this.coas.sort((a, b) => {
        if (a.id === this.currentCOA.id) {
          return -1
        } else if (b.id === this.currentCOA.id) {
          return 1
        } else {
          return a.title > b.title ? 1 : -1
        }
      })
    }
  },
  mounted () {
    process.nextTick(() => {
      axios.get(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts`)
        .then(response => {
          this.coas = response.data.data
          this.sortCoas()
        })
        .catch(err => {
          console.error(err)
        })
    })
  }
}
</script>

<style lang="scss">
  .vheader-main {
    border-radius: 30px;
    background: #86cbac;
    nav {
      position: relative;
      height: 75px;
      width: 95%;
      max-width: 1400px;
      padding: 0 1rem;
      @media print {
        max-width: 100%;
      }
      @media (max-width: 874px) {
        height: 54px;

        .collapse-menu {
          top: 18px;
          position: absolute;
          right: 25px;
        }
      }
      .mobileOpenLink {
        cursor: pointer;
      }
      .livsplan-navbar-brand-link {
        &:hover {
          text-decoration: none;
        }
      }
      .livsplan-navbar-brand {
        /* font-size: 24px;
        font-weight: bold;
        letter-spacing: 1.2px;
        color: #ffffff;
        padding: 0; */
        flex: 0;
        margin-right: 26px;
        max-width: 160px;
        width: 100%;
        @media print {
          min-width:100%;
          height: auto;
          line-height: normal;
        }
        @media (max-width: 1199.98px) {
          margin-right: 20px;
        }

        @media (max-width: 576px) {
          height: 80px;
          line-height: 80px;
          max-width: 110px;
        }
      }
      .current-coa-link {
        cursor: default;
        font-weight: 900;
        display: inline-block;
        width: 100%;
        &:hover {
          color: white;
        }

        &.initial-color {
          color: initial !important;
        }
      }

      .left-link.b-nav-dropdown {
        [aria-expanded="true"].nav-link::after {
          border-top: 0 !important;
          border-bottom: .5em solid !important;
        }

        .nav-link::after {
          margin-left: 0.5em;
          vertical-align: 0.1em;
          border-top: 0.5em solid;
          border-right: 0.5em solid transparent;
          border-bottom: 0;
          border-left: 0.5em solid transparent;
        }

        &.active .nav-link {
          /* border-bottom: 4px solid #36b37e; */
          padding-bottom: 3px;
          color: #5d7769;
        }

        .dropdown-menu {
          padding-top: 1rem;
          padding-bottom: 1rem;
          margin-top: 0.38rem;
          border-radius: 0;

          &:after {
            position: absolute;
            top: -8px;
            left: 20px;
            display: inline-block;
            border-right: 8px solid transparent;
            border-bottom: 8px solid white;
            border-left: 8px solid transparent;
            content: '';
          }

          .dropdown-item {
            font-size: 14px;
            font-weight: 600;
            outline: none;
            line-height: 32px;

            &.active {
              color: #36B37E;
              background-color: white;
            }
            &.router-link-active{
              // border-bottom:4px solid #36b37e;
              color:#36b37e;
              &:hover{
                // border-bottom:4px solid #36b37e;
                color:#C0C0C0
              }
            }
            &:hover{
              background:white;
              color:#36b37e;
            }
          }
        }
      }

      .plan-tablet,
      .right-link {
        .dropdown-toggle {
          padding: 8px 8px 8px 16px;
          line-height: 24px;
          background-color: #74778d;
          border-radius: 0;

          &:after {
            margin-left: 1em;
            margin-right: .5rem;
            vertical-align: .055em;
            font-size: 20px;
          }
        }

        .dropdown-menu {
          background-color: #74788D;
          margin-top: 0;
          border: 0;
          border-radius: 0;
          width: 100%;

          .dropdown-item {
            color: white;
            opacity: 0.7;
            outline: none;
            font-size: 14px;
            line-height: 24px;
            background-color: transparent;

            &:hover, &:focus {
              opacity: 1;
              cursor: pointer;
            }

            &.active {
              opacity: 1;
              font-weight: bold;

              i {
                visibility: visible;
              }
            }

            i {
              font-size: 20px;
              padding: 0.5rem 0.25rem 0.25rem;
              visibility: hidden;
            }
          }
        }
      }

      .nav-link {
        color: white;
        font-size: 14px;
        line-height: 20px;
      }

      .plan-tablet {
        display: none;
      }

      .active-link {
        display: none;
      }
    }

    .main-nav {
      list-style-type: none;
      padding: 0;
      flex: 1;
      li {
        &.nav-item {
          padding: 18px 0;
          @media (max-width: 1199.98px) {
            padding:0;
          }
        }
      }
    }

    .main-nav > li > a {
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin-left: 15px;
        margin-right: 15px;
        font-family: 'Nunito', sans-serif;
    }

    .main-nav > li.active > a {
      /* border-bottom: 4px solid #36b37e; */
      padding: 6px 12px;
      color: #5d7769;
    }

    .main-nav > li:hover > a {
      color: #5d7769;
      cursor: pointer;
      text-decoration: none;
    }

    .collapse-menu {
      cursor: pointer;
      width: 30px;
      height: 18px;
      text-align: center;
      top: 0;
      outline: none;
      z-index: 20;
    }

    .collapse-menu .line {
      height: 2px;
      width: 100%;
      display: block;
      margin-bottom: 6px;
    }

    @media only screen and (max-width: 1199.98px) {
      .collapse-menu {
        top: 28px;
        position: absolute;
        right: 25px;
      }
      .mobileOpenLink {
        position: absolute;
        top: 26px;
        right: 70px;
      }

      .collapse-menu .line {
        background-color: #fff;
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
      }

      @media (min-width: 576px) {
        .collapse-menu.is-expand {
          position: fixed;
          right: 30px;
          top: 50px;
        }
        .plan-tablet {
          display: block !important;
        }

        .active-link {
          display: block !important;
          color: #FFFFFF;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.5px;
          line-height: 24px;
          margin-left: 20px;
        }
      }

      @media (max-width: 874px) {
        .collapse-menu.is-expand {
          position: fixed;
          right: 30px;
          top: 38px;
        }
        .mobileOpenLink {
          top: 16px;
        }
      }

      .collapse-menu.is-expand .line:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .collapse-menu.is-expand .line:nth-child(2) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        margin-top: -8px;
      }

      .collapse-menu.is-expand .line:nth-child(3) {
        transform: translateY(15px);
        opacity: 0;
      }

      .collapse-menu:focus {
        outline: none;
      }

      nav {
        background-color: transparent;
      }

      /* Main Menu for Handheld Devices  */
      .main-nav {
        opacity: 0.96;
        z-index: 2;
        padding: 50px 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 0;
        background-color: #86cbac;
        height: 100%;
        overflow: auto;
        /*CSS animation applied : Slide from Right*/
        -webkit-transition-property: background, width;
        -moz-transition-property: background, width;
        -o-transition-property: background, width;
        transition-property: background, width;
        -webkit-transition-duration: 0.6s;
        -moz-transition-duration: 0.6s;
        -o-transition-duration: 0.6s;
        transition-duration: 0.6s;
      }

      .main-nav.is-expand {
        width: 100vw;
        text-align: left;
        @media (min-width: 576px) {
          padding: 85px 40px 24px;
          z-index: 3;
          .plan-selector {
            display: none;
          }
        }

        @media (max-width:874px) {
          padding: 70px 24px 24px;
          z-index: 3;
          .plan-selector {
            position: absolute;
            top: 18px;
            left: 32px;
            display: block !important;
          }
        }

        & > * {
          opacity: 1;
        }
      }

      .main-nav > * {
        -webkit-transition-property: opacity;
        -moz-transition-property: opacity;
        -o-transition-property: opacity;
        transition-property: opacity;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s;
        opacity: 0;
      }

      .main-nav > li {
        display: block;
        margin-bottom: 15px;

        a {
          font-size: 19px;
          line-height: 32px;
          color: white;
          font-weight: 400;
          letter-spacing: 0;
          margin-right: 0;
          margin-left: 0;
          padding-left: 8px;
          padding-right: 8px;
        }

        @media (max-width: 874px) {
          margin-bottom: 10px;

          a {
            font-size: 20px;
          }
        }
      }

      .tools-insights-link {
        margin-bottom: 52px !important;

        @media (min-width: 576px) {
          margin-bottom: 152px !important;
        }
      }

      .left-link {
        display: none !important;
      }
      .right-link {
        display: none !important;
      }

      .sub-left-link {
        & > a {
          font-weight: 900 !important;
          color: #ffffff !important;
          font-size:130%!important;
        }

        ul {
          display: flex;
          list-style-type: none;
          padding-left: 0;
          padding-top: 0;

          li {
            margin-right: 14px;

            a{
              font-size:100%;
            }
          }

          @media (max-width: 874px) {
            display: block;
            padding-left: 20px;
          }
        }

        &.active {
          > a {
            /* border-bottom: 4px solid #36b37e; */
            padding-bottom: 3px;
            color:#5d7769;
          }
        }
      }

      .sub-right-link {
        a {
          font-size: 18px !important;
        }

        &.profile-settings-link {
          padding-top: 44px;
          border-top: 1px dashed #979797;
        }

        @media (min-width: 576px) {
          margin-bottom: 8px !important;
        }
      }
    }

    @media only screen and (min-width: 1200px) {
      .collapse-menu {
        display: none;
      }
      .main-nav {
        .b-nav-dropdown {
          &.last-menu-link {
            position: absolute;
            right: 0;
          }
          &:last-child {
            ul.dropdown-menu {
              &:after {
                left: 65px;
              }
            }
            ul.dropdown-menu.dropdown-menu-right.show {
              transform: translate3d(24px, 36px, 0px) !important;
            }
            &:hover > ul {
              display: block;
              transform: translate3d(61px, -6px, 0px);
            }
          }
          &:hover > ul {
            display: block;
            transform: translate3d(28px, -7px, 0px);
          }
        }
      }
      /* Main Menu for Desktop Devices  */
      .main-nav {
        display: flex;
        position: relative;
        align-items: center;
      }

      .sub-left-link {
        display: none !important;
      }

      .sub-right-link {
        display: none !important;
      }

      .plan-selector {
        padding-right: 28px !important;
        border-right: 1px solid rgba(255, 255, 255, .13);
      }

      .logout-dropdown {
        margin-left: 12px;

        .fa.fa-ellipsis-v::before {
          content: '•••';
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
          font-size: 15px;
          letter-spacing: 2px;
          margin-top: 2px;
          margin-left: 70px;
        }
      }

    }
  }
</style>
