import axios from 'axios'
import EventBus from '@/event-bus'

/**
 * This function checks if there has been released a new version of Spirecta
 * and tells user to refresh.
 */
export default {
  data () {
    return {
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
      hashChanged: false,
      newHash: ''
    }
  },
  created () {
    if (process.env.NODE_ENV !== 'development') {
      this.initVersionCheck()
    }
    EventBus.$on('hideNotification', (value) => {
      if (value && value.reload) {
        window.location.reload()
      }
    })
  },
  destroyed () {
    EventBus.$off('hideNotification')
  },
  methods: {
    initVersionCheck (frequency = 1000 * 60 * 5) { // Every 5 minutes
      setInterval(() => {
        this.checkVersion()
      }, frequency)
    },
    async checkVersion () {
      try {
        const fileResponse = await axios.get('/version.json')
        this.newHash = fileResponse.data.hash
        this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash)
      } catch (error) {
        this.loading = false
        if (!error.response) {
          this.errorStatus = 'Error: Network Error'
        } else {
          this.errorStatus = error.response.data.message
        }
      }
    },
    hasHashChanged (currentHash, newHash) {
      if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        return true
      }
      return currentHash !== newHash
    }
  },
  watch: {
    hashChanged: function (newVal) {
      if (newVal && newVal === true) {
        EventBus.$emit('refresh-notification-show', {
          autoHide: false,
          reloadPage: true
        })
      }
    }
  }
}
