/* eslint-disable promise/param-names */
import { SET_SETTINGS_PAGINATION_ROWS_PER_PAGE, SET_SETTINGS_ACTIVE_LANG_ID, SET_IMPORT_WIZARDS_ROWS_PER_PAGE_VIEW, SET_IMPORT_WIZARDS_ROWS_PER_PAGE_ACTIONS, SET_ACCOUNT_GROUPS_MULTISELECT } from '../actions/settings'
import { SpirectaApp } from '../../main.js'
import axios from 'axios'

const state = {
  paginationRowsPerPage: localStorage.getItem('paginationRowsPerPage') || 25,
  activeLanguageId: localStorage.getItem('activeLanguageId') || 'en',
  importPaginationRowsView: localStorage.getItem('importPaginationRowsView') || 25,
  importPaginationRowsAction: localStorage.getItem('importPaginationRowsAction') || 25,
  accountGroups: {
    income: null,
    expense: null,
    asset: null,
    liability: null
  }
}

/* GETTERS */
const getters = {
  getPaginationRowsPerPage: state => {
    return state.paginationRowsPerPage
  },
  getActiveLang: state => {
    return state.activeLanguageId
  },
  getImportPaginationRowsView: state => {
    return state.importPaginationRowsView
  },
  getImportPaginationRowsAction: state => {
    return state.importPaginationRowsAction
  },
  getAccountGroupOptions: state => {
    return state.accountGroups
  }
}

/* MUTATIONS */
const mutations = {
  SET_SETTINGS_PAGINATION_ROWS_PER_PAGE (state, rowsPerPage) {
    state.paginationRowsPerPage = rowsPerPage
  },
  SET_SETTINGS_ACTIVE_LANG_ID (state, langId) {
    state.activeLanguageId = langId
    SpirectaApp.$i18n.locale = langId
    if (typeof this.$zendesk !== 'undefined') {
      this.$zendesk.zE('webWidget', 'setLocale', langId)
    }
  },
  SET_IMPORT_WIZARDS_ROWS_PER_PAGE_VIEW (state, rowsPerPage) {
    state.importPaginationRowsView = rowsPerPage
  },
  SET_IMPORT_WIZARDS_ROWS_PER_PAGE_ACTIONS (state, rowsPerPage) {
    state.importPaginationRowsAction = rowsPerPage
  },
  SET_ACCOUNT_GROUPS_MULTISELECT (state, payload) {
    const income = []
    const expense = []
    const asset = []
    const liability = []
    Object.keys(payload).forEach(function (key) {
      const AssociateAccounts = payload[key].accounts.filter((el) => el.status !== 'suspended')
      if (AssociateAccounts.length > 0) {
        const accountItem = { title: payload[key].label, title_with_no_code: payload[key].title, title_with_code: payload[key].title_with_code, type: payload[key].type, code: payload[key].code, accounts: AssociateAccounts }
        if (payload[key].type === 'income') {
          income.push(accountItem)
        } else if (payload[key].type === 'expense') {
          expense.push(accountItem)
        } else if (payload[key].type === 'asset') {
          asset.push(accountItem)
        } else if (payload[key].type === 'liability') {
          liability.push(accountItem)
        }
      }
    })
    state.accountGroups = Object.assign({}, state.accountGroups, {
      income,
      expense,
      asset,
      liability
    })
  }
}

/* ACTIONS */
const actions = {
  [SET_SETTINGS_PAGINATION_ROWS_PER_PAGE]: ({ commit }, payload) => {
    localStorage.setItem('paginationRowsPerPage', payload)
    commit(SET_SETTINGS_PAGINATION_ROWS_PER_PAGE, payload)
  },
  [SET_SETTINGS_ACTIVE_LANG_ID]: ({ commit }, payload) => {
    localStorage.setItem('activeLanguageId', payload)
    commit(SET_SETTINGS_ACTIVE_LANG_ID, payload)
  },
  [SET_IMPORT_WIZARDS_ROWS_PER_PAGE_VIEW]: ({ commit }, payload) => {
    localStorage.setItem('importPaginationRowsView', payload)
    commit(SET_IMPORT_WIZARDS_ROWS_PER_PAGE_VIEW, payload)
  },
  [SET_IMPORT_WIZARDS_ROWS_PER_PAGE_ACTIONS]: ({ commit }, payload) => {
    localStorage.setItem('importPaginationRowsAction', payload)
    commit(SET_IMPORT_WIZARDS_ROWS_PER_PAGE_ACTIONS, payload)
  },
  [SET_ACCOUNT_GROUPS_MULTISELECT]: async ({ commit }) => {
    try {
      const groupResponse = await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=income,expense,asset,liability&log=VuexState`)
      commit(SET_ACCOUNT_GROUPS_MULTISELECT, groupResponse.data.data)
    } catch (e) {
      console.error(e)
    }
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
