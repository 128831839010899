import Vue from 'vue'
import VueI18n from 'vue-i18n'

import sv from './sv-SE.json'
import en from './en-US.json'
import da from './da-DK.json'

Vue.use(VueI18n)

const locale = 'en'

const messages = {
  sv,
  en,
  da
}

export default new VueI18n({
  locale,
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: true
})
