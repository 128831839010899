<template>
  <div id="app">
    <v-header ref="vHeader"></v-header>
    <router-view :current-user="currentUser" :current-coa="currentCOA"></router-view>
    <v-footer></v-footer>
    <bootstrap-toast></bootstrap-toast>
    <notification-component ref="networthNotification" variant='danger' :autoHide="!currentCOA" :key="'networth'" :persist="true">
      <template #message>
        <i18n path="common.create_networth" tag="p">
          <b-link href="https://forum.spirecta.se/t/systemkategorin-eget-kapital/60" slot="read_more_link" target="_blank">{{ $t('common.read_more_text')}}</b-link>
          <b-link to="/assets-liabilities/accounts/create/liability" slot="create_one_link">{{ $t('common.create_one_text')}}</b-link>
        </i18n>
      </template>
    </notification-component>
    <notification-component ref="notificationComp" :variant="subscriptionExp ? 'danger' : 'success'" :autoHide="subscriptionExp === false" :persist="true" :key="'subscription'">
      <template #message v-if="subscriptionExp">
        <i18n path="common.subscription_expire" tag="p">
          <b-link slot="link" to="/settings/subscription">{{ $t('common.extend_here')}}</b-link>
        </i18n>
      </template>
    </notification-component>
    <refresh-notification-component ref="refreshNotificationComp" :key="'refreshNotify'">
      <template #message>
        <p>
          <span class="font-weight-bold">{{ $t('common.notifications.new_update_available.title')}} 👍🥂</span>&nbsp;
          <span class="">{{ $t('common.notifications.new_update_available.description')}}</span>&nbsp;
          <b-link target="_blank" href="https://forum.spirecta.se/tag/ny-version">{{ $t('common.refresh_read_more')}}</b-link>
        </p>
      </template>
    </refresh-notification-component>
  </div>
</template>

<script>
import refreshMixing from '@/mixins/refreshMixins'
import NotificationComponent from '@/components/common/NotificationComponent'
import RefreshNotificationComponent from '@/components/common/RefreshNotificationComponent'
import vheader from '@/components/header'
import vfooter from '@/components/footer'
import { mapState, mapActions } from 'vuex'
import BootstrapToast from './components/common/BootstrapToast'
import { SET_SETTINGS_ACTIVE_LANG_ID, SET_SETTINGS_PAGINATION_ROWS_PER_PAGE } from '@/store/actions/settings'
import { getAuth, setupAxiosDefaults } from './utils'

export default {
  name: 'app',
  mixins: [refreshMixing],
  components: {
    BootstrapToast,
    'v-header': vheader,
    'v-footer': vfooter,
    NotificationComponent,
    RefreshNotificationComponent
  },
  mounted () {
    if (this.isAuthenticated) {
      setupAxiosDefaults(getAuth())
      const self = this
      const promiseArray = [
        this.fetchData(),
        this.loadCurrentCOA()
      ]
      Promise.all(promiseArray)
        .then(function (result) {
          const userDetails = result[0]
          const coaResult = result[1]
          if (userDetails && userDetails.default_lang) {
            self.$store.dispatch(SET_SETTINGS_ACTIVE_LANG_ID, userDetails.default_lang)
          }
          if (coaResult && Object.prototype.hasOwnProperty.call(coaResult, 'default_pagination_rows') && coaResult.default_pagination_rows && coaResult.default_pagination_rows !== 0) {
            self.$store.dispatch(SET_SETTINGS_PAGINATION_ROWS_PER_PAGE, coaResult.default_pagination_rows)
          }
        })
        .catch(err => {
          console.error(err)
        })
    }
  },

  methods: {
    ...mapActions('user', ['fetchData', 'loadCurrentCOA'])
  },

  computed: {
    ...mapState('user', ['currentUser', 'currentCOA', 'isAuthenticated', 'subscriptionExp'])
  },
  watch: {
    subscriptionExp: {
      handler: function () {
        if (this.subscriptionExp === true) {
          this.$refs.notificationComp.handleShow()
        } else {
          if (typeof this.$refs.notificationComp !== 'undefined') {
            this.$refs.notificationComp.hideToast()
          }
        }
      },
      immediate: true
    },
    currentUser: {
      handler (value) {
        // console.log(value)
        if (value) {
          const defaultLang = Object.prototype.hasOwnProperty.call(value, 'default_lang') && value.default_lang ? this.currentUser.default_lang : null
          if (typeof this.$zendesk !== 'undefined' && Object.prototype.hasOwnProperty.call(this.$zendesk, 'zE')) {
            if (defaultLang) {
              setTimeout(function () {
                this.$zendesk.zE('webWidget', 'setLocale', value.default_lang)
              }.bind(this), 300)
            }
            const userFirstName = Object.prototype.hasOwnProperty.call(value, 'firstname') && value.firstname ? value.firstname : ''
            const userLastName = Object.prototype.hasOwnProperty.call(value, 'lastname') && value.lastname ? value.lastname : ''
            const userFullName = (userFirstName + ' ' + userLastName).toString().trim()
            setTimeout(function () {
              this.$zendesk.zE('webWidget', 'prefill', {
                name: { value: userFullName },
                email: { value: (userFullName.length === 0 ? '' : value.email) }
              })
            }.bind(this), 300)
          }
        } else {
          if (typeof this.$zendesk !== 'undefined' && Object.prototype.hasOwnProperty.call(this.$zendesk, 'zE')) {
            setTimeout(function () {
              this.$zendesk.zE('webWidget', 'prefill', {
                name: { value: '' },
                email: { value: '' }
              })
            }.bind(this), 300)
          }
        }
      },
      deep: true
    },
    currentCOA: {
      handler: function () {
        if (this.currentCOA) {
          if (Object.prototype.hasOwnProperty.call(this.currentCOA, 'networth_account_id') && this.currentCOA.networth_account_id === null) {
            this.$refs.networthNotification.handleShow()
          }
        } else {
          if (typeof this.$refs.networthNotification !== 'undefined') {
            this.$refs.networthNotification.hideToast()
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
  @import './assets/scss/print.scss';
  @import './assets/scss/app.scss';
  @import './assets/fonts/solid.scss';
  @import './assets/fonts/stroke.scss';
</style>
