import router from '@/router'
import axios from 'axios'
import { setupAxiosDefaults } from '@/utils'
import { SET_SETTINGS_ACTIVE_LANG_ID } from '../actions/settings'

const AUTH_PARAMS = {
  grant_type: 'password',
  client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
  client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
}

const initialState = () => {
  const currentUser = {
    id: null,
    firstname: null,
    lastname: null,
    default_lang: null,
    email: null,
    show_tutorial: null,
    strict_accounting_mode: null,
    tink_app: process.env.VUE_APP_TINK_APP,
    tink_client_id: process.env['VUE_APP_TINK_' + process.env.VUE_APP_TINK_APP.toUpperCase() + '_CLIENT_ID']
  }

  let isAuthenticated = false
  const loading = false
  const currentCOA = {
    title: null,
    currency: null,
    currency_iso: null,
    default_budget_id: null,
    default_pagination_rows: null,
    income_observation_account_id: null,
    expense_observation_account_id: null,
    prefix_account_title_with_code: null,
    locale: null
  }

  let auth = window.localStorage.getItem('auth', null)

  if (auth !== null) {
    auth = JSON.parse(auth)
    if (auth) {
      isAuthenticated = auth.access_token !== null
      // setupAxiosDefaults(auth)
    }
  }

  return {
    currentUser: currentUser,
    isAuthenticated: isAuthenticated,
    loading: loading,
    currentCOA: currentCOA,
    subscriptionExp: false,
    subscriptionData: {}
  }
}

const getters = {
  currentUser: state => state.currentUser,
  currentCOA: state => state.currentCOA,
  subscriptionExp: state => state.subscriptionExp,
  subscriptionData: state => state.subscriptionData,
  loading: state => state.loading
}

const actions = {
  subscriptionData: ({ commit }, value) => {
    commit('setSubscriptionData', value)
  },
  subscriptionExpired: ({ commit }, value) => {
    commit('setSubscriptionExp', value)
  },
  fetchData: ({ state, commit, dispatch }) => {
    commit('setLoading', true)

    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_ROOT_API + '/me')
        .then(response => {
          if (typeof response !== 'undefined' && response.data) {
            const oUser = response.data.data
            oUser.tink_app = process.env.VUE_APP_TINK_APP
            oUser.tink_client_id = process.env['VUE_APP_TINK_' + process.env.VUE_APP_TINK_APP.toUpperCase() + '_CLIENT_ID']
            commit('setCurrentUser', oUser)
            dispatch('user/loadCurrentCOA', null, { root: true })

            if (oUser.subscription_status_subscribed) {
              dispatch('subscriptionExpired', false)
            } else {
              dispatch('subscriptionExpired', true)
            }
            dispatch(SET_SETTINGS_ACTIVE_LANG_ID, oUser.default_lang, { root: true })
            resolve(response.data.data)
          }
        })
        .catch(error => {
          console.error(error)

          commit('setIsAuthenticated', false)
          commit('setCurrentUser', null)
          commit('setCurrentCoa', null)
          commit('setSubscriptionData', {})
          commit('setSubscriptionExp', false)
          window.localStorage.setItem('auth', null)

          if (error.response.status === 423) {
            window.location.href = window.location.origin + '/registration-is-not-completed'
          }

          reject(error)
        })
    })
  },

  loadCurrentCOA: ({ state, commit, dispatch }) => {
    if (state.currentUser) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_ROOT_API + '/charts-of-accounts/active')
          .then(response => response.data.data)
          .then(response => {
            if (response) {
              const defaultPaginationRows = response && Object.prototype.hasOwnProperty.call(response, 'default_pagination_rows') && response.default_pagination_rows && response.default_pagination_rows > 0 ? response.default_pagination_rows : null
              window.localStorage.setItem('defaultRows', defaultPaginationRows)
              commit('setCurrentCoa', response)
              resolve(response)
            }
          })
          .catch(error => {
            console.error('Get user error: ', error)
            reject(error)
          })
      })
    }
  },

  setAuth: ({ state, commit, dispatch, getters }, auth) => {
    window.localStorage.setItem('auth', JSON.stringify(auth))
  },

  login: ({ commit, state, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      user.username = user.email
      delete user.email
      axios.post(process.env.VUE_APP_ROOT_API_EXCL_API_PREFIX + '/oauth/token', { ...user, ...AUTH_PARAMS })
        .then(response => {
          dispatch('user/setAuth', response.data, { root: true })

          setupAxiosDefaults(response.data)

          commit('setIsAuthenticated', response.data.access_token !== null)

          dispatch('user/fetchData', null, { root: true })
          resolve(response.data)
        })
        .catch(error => {
          if (error.response && error.response.code === 401) {
            return reject(error.response.message)
          }
          reject(error)
        })
    })
  },

  registerTokensByBankIdLogin: ({ commit, state, dispatch }, tokens) => {
    return new Promise((resolve) => {
      dispatch('user/setAuth', tokens, { root: true })

      setupAxiosDefaults(tokens)

      commit('setIsAuthenticated', tokens.access_token !== null)

      dispatch('user/fetchData', null, { root: true })

      resolve(tokens)
    })
  },

  logout: ({ commit, state, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (state.isAuthenticated) {
        let auth = window.localStorage.getItem('auth', null)

        if (auth !== null) {
          auth = JSON.parse(auth)
          if (auth) {
            axios.post(process.env.VUE_APP_ROOT_API + '/logout')
              .then(response => {
                window.localStorage.removeItem('auth')
                dispatch('user/setAuth', null, { root: true })
                commit('setIsAuthenticated', false)
                commit('setSubscriptionExp', false)
                commit('setCurrentCoa', null)
                commit('setCurrentUser', {
                  id: null,
                  firstname: null,
                  lastname: null,
                  email: null,
                  default_lang: null,
                  show_tutorial: null,
                  strict_accounting_mode: null,
                  tink_app: null,
                  tink_client_id: null
                })
                commit('setIsAuthenticated', false)
                commit('setSubscriptionData', {})
                router.push({ name: 'login' })
                return resolve(true)
              })
              .catch(error => {
                console.error('Logout Error: ', error)
                return reject(error)
              })
          }
        }
      }
      dispatch('user/setAuth', null, { root: true })
      router.push({ name: 'login' })
      resolve(true)
    })
  },

  refreshToken: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ROOT_API_EXCL_API_PREFIX + '/oauth/token/refresh')
        .then(response => {
          commit('setCurrentUser', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setCurrentUser: (state, value) => {
    state.currentUser = value
  },
  setSubscriptionData: (state, value) => {
    state.subscriptionData = value
  },
  setSubscriptionExp: (state, value) => {
    state.subscriptionExp = value
  },
  setCurrentCoa: (state, value) => {
    state.currentCOA = value
  },
  setLoading: (state, value) => {
    state.loading = value
  },
  setIsAuthenticated: (state, value) => {
    state.isAuthenticated = value
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations: mutations,
  getters: getters,
  actions: actions
}
